import React, { useContext } from "react";
import "./Intro.css";
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
// import boy from "../../img/boy.png";
import boy from "../../image/boy.png";
import glassesimoji from "../../img/glassesimoji.png";
import thumbup from "../../img/thumbup.png";
import crown from "../../img/crown.png";
import FloatinDiv from "../FloatingDiv/FloatingDiv";
import Github from "../../image/logo-instagram-png-13549-removebg-preview.png";
import LinkedIn from "../../image/facebook-13-removebg-preview.png";
import Instagram from "../../image/telegram-icon-telegram-logo-11563072765e0pl0xsrfe__1_-removebg-preview.png";
import facebook from "../../image/whatsapp-png-whatsapp-transparent-png-image-1012__1_-removebg-preview.png";
import youtube from "../../image/youtube-removebg-preview.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };

  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="Intro" id="Intro">
      {/* left name side */}
      <div className="i-left">
        <div className="i-name">
          {/* yahan change hy darkmode ka */}
          {/* <span style={{ color: darkMode ? "white" : "" }}>Hy! I Am</span> */}
          <span style={{ color: darkMode ? "white" : "rgb(0,102,204)",fontSize:"2.5rem",fontFamily: "Arial,sans-serif"}}>EXAMIRROR</span>

          <span>Get Ready To Compete</span>
          {/* <span>
            Frontend Developer with high level of experience in web designing
            and development, producting the Quality work
          </span> */}
          <span>
          Examirror is an exceptional platform for modernizing education. Our institute focuses at infusing high quality education via effective exam strategies. It primarily aims at reaching remote minds thus achieving individualized learning despite any geographical locations. Examirror provides live classes, informational content, test series, mock test,doubt clearing sessions  to excel at center as well as state level exams for admissions at top-notch universities in India.This institute would shape each aspirant to secure best platforms irrespective of educational barriers. Our verified team of experts would help students excel their aims with insightful tips and tricks that would automatically adjust to an individual learning competence.
          </span>
        </div>
        <Link to="contact" smooth={true} spy={true}>
          <button className="button i-button">Contact</button>
        </Link>
        {/* social icons */}
        <div className="i-icons">
          <a href="https://www.instagram.com/examirror1" target="_blank"> <img src={Github} alt="" /></a>
          <a href="https://www.facebook.com/profile.php?id=100093471816955" target="_blank"><img src={LinkedIn} alt="" /></a> 
          <a href="https://t.me/examirror" target="_blank"> <img src={Instagram} alt="" /></a>
          <a href="https://whatsapp.com/channel/0029Va4shYGHgZWfoJyrmD3a" target="_blank"> <img src={facebook} alt="" /></a>
          <a href="https://www.youtube.com/@examirror" target="_blank"> <img src={youtube} alt="" height="82px" width="90px"/></a>
        </div>
      </div>
      {/* right image side */}
      <div className="i-right">
       <img src={Vector1} alt="" />
       <img src={Vector2} alt="" />
        <img src={boy} alt="" />
        {/* animation */}
        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-24%" }}
          transition={transition}
          src={glassesimoji}
          alt=""
        />

        <motion.div
          initial={{ top: "-4%", left: "74%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv img={crown} text1="Exciting" text2="Offers" />
        </motion.div>

        {/* animation */}
        <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
          {/* floatinDiv mein change hy dark mode ka */}
          <FloatinDiv img={thumbup} text1="Best" text2="Wishes" />
        </motion.div>

        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Intro;
