import React, { useContext } from "react";
import "./Works.css";
import Upwork from "../../image/w1-removebg-preview.png";
import Fiverr from "../../image/w2-removebg-preview.png";
// import Amazon from "../../img/amazon.png";
import Logo from "../../image/logo1.png";
import Shopify from "../../image/w3-removebg-preview.png";
import Facebook from "../../image/w4-removebg-preview.png";
import w5 from "../../image/w5-removebg-preview.png";
import w6 from "../../image/w6-removebg-preview.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import {Link} from 'react-scroll'
const Works = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  return (
    <div className="works" id="works">
      {/* left side */}
      <div className="w-left">
        <div className="awesome">
          {/* dark Mode */}
          <span style={{ color: darkMode ? "white" : "" }}>
            Cover All these Syllabus with
          </span>
          
          <span>Quality & Perfection</span>
          <spane>
            Start by outlining your course's main objectives and goals
            <br />
            Break down the course into individual lessons or modules. Each lesson should have a clear topic.
            <br />
            Written explanations, lecture notes, and readings
            <br />
            Videos, animations, and interactive simulations
          </spane>
          <Link to="contact" smooth={true} spy={true}>
            <button className="button s-button">Contact us</button>
          </Link>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>

        {/* right side */}
      </div>
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle">
            <img src={Upwork} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={Facebook} alt="" />
          </div>
          <div className="w-secCircle1">
            <img src={Logo} alt="" />
          </div>{" "}
          <div className="w-secCircle">
            <img src={Shopify} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={w6} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={Fiverr} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={w5} alt="" />
          </div>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle blueCircle"></div>
        <div className="w-backCircle yellowCircle"></div>
      </div>
    </div>
  );
};

export default Works;
