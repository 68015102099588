import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Autoplay } from 'swiper';

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../image/Arifsir.png";
import profilePic2 from "../../image/rajendarsir.png";
import profilePic3 from "../../image/saurabh.png";
import profilePic4 from "../../image/rishu.png";

SwiperCore.use([Autoplay]);

const Testimonial = () => {
  const clients = [
    {
      img: profilePic1,
      Name:"Dr. Arif Md Sattar",
      expreience: "25+ years teaching Experience",
      qualification:"(Ph.D Computer Science)",
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex officiis molestiae quod tempora laudantium, cumque error a nisi placeat quae exercitationem, maiores reiciendis! Eaque dicta minima, iure maiores dolorum sed.",
    },
    {
      img: profilePic2,
      Name:"Rajendra Kumar",
      expreience: "15+ years teaching Experience",
      qualification:"M.Sc. Mathematics",
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex officiis molestiae quod tempora laudantium, cumque error a nisi placeat quae exercitationem, maiores reiciendis! Eaque dicta minima, iure maiores dolorum sed.",
    },
    {
      img: profilePic3,
      Name:"Saurabh Kumar",
      expreience: "5+ years teaching expreience",
      qualification:"(M.Tech Computer Science) JNU New Delhi",
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex officiis molestiae quod tempora laudantium, cumque error a nisi placeat quae exercitationem, maiores reiciendis! Eaque dicta minima, iure maiores dolorum sed.",
    },
    {
      img: profilePic4,
      Name:"Rishu Kumar",
      expreience: "5+ years teaching expreience",
      qualification:"(M.Tech Computer Science) JNU New Delhi",
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex officiis molestiae quod tempora laudantium, cumque error a nisi placeat quae exercitationem, maiores reiciendis! Eaque dicta minima, iure maiores dolorum sed.",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        {/* <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from me...</span> */}
        <span>Enjoy classes with </span>
        <span>Exceptional Faculties </span>
        <span>of ours...</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        autoplay={{delay:1000}}
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                {/* <span>{client.review}</span> */}
                <span>{client.Name}</span>
                <span>{client.qualification}</span>
                <span style={{"margin-left":"30px"}}>{client.expreience}</span>
                {/* <span>{client.review}</span> */}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
