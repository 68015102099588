import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
// import Sidebar from "../../img/sidebar.png";
// import Ecommerce from "../../img/ecommerce.png";
// import HOC from "../../img/hoc.png";
// import MusicApp from "../../img/musicapp.png";
import Sidebar from "../../image/ranker.png";
import Ecommerce from "../../image/examirror banner.png";
import HOC from "../../image/p1.png";
import MusicApp from "../../image/p2.png";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>Our Achivers</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        // slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
        effect={'coverflow'}
        breakpoints={{
          640: {
            slidesPerView: 0.5
          },

          764:{
            slidesPerView: 2
          },
          1000: {
            slidesPerView: 3

          }
        }}
      >
        {/* <SwiperSlide>
          <img src={Sidebar} alt="" />
        </SwiperSlide> */}
        <SwiperSlide>
          <img src={Ecommerce} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={MusicApp} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={HOC} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
